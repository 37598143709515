import React from 'react';
import data from './configuration/application.json';
import { Row, Container, Col, ListGroupItem } from 'react-bootstrap';
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams

} from "react-router-dom";
var QRCode = require('qrcode.react');




function App() {
  return (
    <Router>
      <div>
        <Switch>

          <Route exact path="/open/:id" children={<Child />} />
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function NoMatch() {
  window.location.href = 'https://www.veiligophalen.nl';
}

function Child() {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.


  let { id } = useParams();



  let url = data.url;


  if(id != null && id.length < 1)
      window.location.href = 'https://www.veiligophalen.nl';

  if (url.endsWith("/"))
    url = url.substring(0, url.length - 1)
  return (
    <div className="main">
      <Container fluid="md">

    
      <Row  >

        <Col md={{ span: 6, offset: 3 }}>

          <h1>Veiligophalen QR Genereren</h1>
          <h2>QR Code Succesvol gegenereerd</h2>
          <div className="wrapper">
            <div className="first">
              <h3>
                Scan deze QR code met een QR-code app of de QR code scanner in App.
        </h3>
            </div>
            <div className="second">
              <QRCode className="QRpart" value={url + "/_LCPF_MIN_LCPF_DEMO__klantcode=" + id} />
            </div>
          </div>
        </Col>

      </Row> 
      </Container>
    </div>


    // {/* <div className="App">
    //   <h1>Veiligophalen QR Genereren</h1>
    //   <h2>QR Code Succesvol gegenereerd</h2>


    //   </div>
    // </div> */}
  );

}



export default App;
